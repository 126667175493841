import { ChartsContainer } from '@/shared/atoms';
import { HAS_NO_ESTIMATED_TEXT, THEME } from '@/shared/constants';
import {
  AgeBreakdownChart,
  AreaProvinceCityChart,
  BarChartWithMoreModal,
  GenderBreakdownChart
} from '@/shared/molecules';
import { useXhsAccountInterestQuery, useXhsProfileAudienceQuery } from '@/graphql';
import { useInfluencerProfileOpacityContent } from '../../hooks';
import { InfluencerSelectedAccountInfo } from '../../types';
import { InfluencerProfileAudienceWrapper } from '../shared';

export interface InfluencerXhsProfileAudienceProps {
  influencerId: number;
  socialAccount: InfluencerSelectedAccountInfo;
}

export const InfluencerXhsProfileAudience = ({ influencerId, socialAccount }: InfluencerXhsProfileAudienceProps) => {
  const { opacityContent, hasAudienceDemographics } = useInfluencerProfileOpacityContent({
    influencerId,
    socialAccount
  });
  const { data: audienceData, loading } = useXhsProfileAudienceQuery({
    variables: { pk: influencerId, socialAccountId: socialAccount.id }
  });
  const { data: accountInterestData } = useXhsAccountInterestQuery({
    variables: {
      pk: influencerId,
      socialAccountId: socialAccount.id
    }
  });

  const chartOpacityContent = hasAudienceDemographics ? opacityContent : { wrapperText: HAS_NO_ESTIMATED_TEXT };
  const deviceRates = accountInterestData?.xhsAccountInterest?.deviceRates;
  const interestRates = accountInterestData?.xhsAccountInterest?.interestRates;

  return (
    <InfluencerProfileAudienceWrapper
      loading={loading}
      data={audienceData?.xhsProfileAudience}
      hasAudienceDemographics={hasAudienceDemographics}
    >
      {({ data: { maleRate, femaleRate, ageRates, provinceRates, cityRates } }) => (
        <>
          <ChartsContainer css={{ height: '385px' }}>
            <GenderBreakdownChart maleRate={maleRate} femaleRate={femaleRate} {...chartOpacityContent} />
            <AgeBreakdownChart
              ageGroup={ageRates.ageGroup}
              totalRate={ageRates.rate}
              xAxisCategories={ageRates.ageGroup?.map((item) =>
                item.start === undefined
                  ? `-${item.end}`
                  : item.end === undefined
                    ? `${item.start}+`
                    : `${item.start}-${item.end}`
              )}
              {...chartOpacityContent}
            />
          </ChartsContainer>
          <ChartsContainer css={{ height: '385px', borderTop: THEME.table.row.border }}>
            <AreaProvinceCityChart provinceRates={provinceRates || []} cityRates={cityRates || []} hasRelativeMaxY />
            <BarChartWithMoreModal
              dataset={deviceRates || []}
              title="Device Distribution"
              modalTitle="Device Distribution"
              hasRelativeMaxY
            />
            <BarChartWithMoreModal
              dataset={interestRates || []}
              title="Interest"
              modalTitle="Interest"
              hasRelativeMaxY
              hasCategoriesTranslate
            />
          </ChartsContainer>
        </>
      )}
    </InfluencerProfileAudienceWrapper>
  );
};
